'use client';

/* eslint-disable @next/next/no-img-element */
import React from 'react';
import HeaderLinks from '../HeaderLinks';
import Logo from '@public/mc/logo.svg';
import { ROUTES } from '@/constants/routes/default';
import Link from 'next/link';

function HeaderMC() {
  return (
    <header className="flex md:px-10 max-md:px-2 min-h-[122px] header">
      <div className="max-w-[940px] w-full h-full flex justify-between items-center m-auto flex-wrap gap-4 md:pr-6 max-md:pr-1">
        <Link href={ROUTES.home}>
          <Logo className="w-[250px] h-[30px] flex-shrink-0" />
        </Link>
        <HeaderLinks
          listHeaderCn="px-3.5 py-2 text-white3"
          dropdownClassNames={{
            listCn: 'max-w-[200px] right-0 bg-secondary py-2 rounded-lg',
            elementCn:
              'text-sm text-main-text-color h-9 px-5 flex items-center hover:bg-mine-shaft',
            rootCn: '',
            iconCn:
              'h-10 w-10 rounded-lg [&>svg>path]:!fill-primary hover:bg-secondary [&>svg>path]:hover:!fill-white',
            activeIconCn: 'bg-secondary [&>svg>path]:!fill-white',
          }}
          componentCn="flex gap-5 items-center"
          linkCn="search-link text-primary no-underline capitalize text-sm font-bold default-transition hover:text-white hover:underline hover:decoration-2 hover:underline-offset-2"
        />
      </div>
    </header>
  );
}

export default HeaderMC;
